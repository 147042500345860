import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewManagerService } from 'src/app/managers/document-view-manager.service';
import {
  DateLimit,
  DocumentData,
  InvoiceData,
  PaymentData,
  PaymentTypes,
} from 'src/app/models/documet-data.interface';
import { MoneySettings } from 'src/app/models/money-settings';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { DataAdapterService } from 'src/app/services/commons/data-adapter.service';
import { environment } from 'src/environments/environment';
import { CountryISOEnum } from '@siigo/siigo-library-enums';
import { AccountBalanceModel } from 'src/app/models/account-balance';
import { DuesTableModel } from 'src/app/models/dues-table';
import { SiigoNotificationsInlineMolecule } from '@siigo/siigo-notifications-inline-molecule-angular';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-angular';
import { DownloadService } from 'src/app/services/commons/dowload.service';

@Component({
  selector: 'app-invoice-view-page',
  templateUrl: './invoice-view-page.component.html',
  styleUrls: ['./invoice-view-page.component.css'],
})
export class InvoiceViewPageComponent implements OnInit {
  private encryptedData: string = '';
  private extraFields: string = '';
  public isPreviewModalOpen: boolean = false;
  public documentData: DocumentData;
  public Date: DateLimit;
  public dateLimit: DateLimit;
  public totalAmount: string;
  public invoiceData: InvoiceData;
  public state: any;
  public messageNotification: any;
  public typeNotification: any;
  public contact: any;
  public showSpinner: boolean = false;
  public showErrorPage: boolean = false;
  public isSaleNote: boolean = false;
  // this means that the payement type has an expiration date
  paymentTypeWithDue: number = 1;
  public showPayOnlineButton: boolean;

  // Start Payment
  public env: any;
  public isMexico: boolean = false;
  public duesTypeInfo: AccountBalanceModel[] = [];
  public data: DuesTableModel[] = [];
  public notificationMessage: string;
  private dictionaryValidInput = new Map();
  private dictionaryInput = new Map();
  private dictionaryTotalInput = new Map();
  public notificationStatus: boolean = false;
  isLoadingData: boolean = false;
  dueName: string;
  dueConsecutive: string;
  accountCode: string;
  defaultValue: string | number = 0;
  defaultValues: (number | string)[] = [];
  @ViewChildren('paymentInput') paymentInputs: QueryList<SiigoInputAtom>;
  @ViewChild('siigoNotificationInlineValidation', { static: false })
  siigoNotificationInlineValidation: SiigoNotificationsInlineMolecule;
  public total: any;
  public totalInLocalMoney: number = 0;
  public loading: boolean = false;
  localMoney: boolean = false;
  // Finish Payment

  constructor(
    private _route: ActivatedRoute,
    private documentViewManagerService: DocumentViewManagerService,
    private _translate: TranslateService,
    private _dataAdapterService: DataAdapterService,
    public _downloadXml: DownloadService,
    private element: ElementRef
  ) {
    this.isMexico =
      Number(environment.countryCodeISO) === CountryISOEnum.Mexico;
    this.getQueryParams();
    this.env = environment;
  }

  async ngOnInit() {
    const isColombia =
      Number(environment.countryCodeISO) === CountryISOEnum.Colombia;
    await this.loadData(isColombia);
    this.loadDocumentInfo();
    var isDuePayment = await this.hasPaymentWithDue();
    const showPayment = isDuePayment
      ? await this.validateActivePayOnlineButtonDuePayment()
      : await this.validateActivePayOnlineButtonNotDuePayment();
    this.showPayOnlineButton = showPayment;
    this.validateTypeNotification();
  }

  private async loadData(isColombia: boolean) {
    this.showSpinner = true;
    await this.documentViewManagerService
      .loadData(this.encryptedData, this.extraFields, isColombia)
      .then((documentData: DocumentData) => {
        if (documentData) {
          this.documentData = documentData;

          this.totalAmount = this._dataAdapterService.numberToDueTypeFormat(
            documentData.balance.totalValue,
            documentData.balance.moneyCode
          );

          const metadata = this.documentData.metadata;
          const created = metadata ? metadata.created : null;
          const dateString =
            created && created.date ? created.date.toString() : null;

          const aditionalInfo = this.documentData.aditionalInfo;
          const createdDueDate = aditionalInfo ? aditionalInfo : null;

          const dateStringLimit =
            createdDueDate && createdDueDate.dueDate
              ? documentData.aditionalInfo.dueDate.toString()
              : null;
          if (dateString) {
            const date = new Date(dateString).toISOString();

            this.Date = {
              createdDate: formatDate(
                date.toString(),
                "d MMM. yyyy 'a las' HH:mm",
                'en-US'
              ),
            };
          } else {
            this.Date = {
              createdDate: 'Fecha no disponible',
            };
          }
          if (dateStringLimit) {
            const dateLimit = new Date(dateStringLimit).toISOString();
            this.dateLimit = {
              createdDate: formatDate(
                dateLimit.toString(),
                "'Fecha límite de pago:' d MMM. yyyy",
                'en-US'
              ),
            };
          } else {
            this.dateLimit = {
              createdDate: 'Fecha no disponible',
            };
          }
          this.invoiceData = {
            docClass: this.documentData.docClass,
            docCode: this.documentData.docCode,
            docNumber: this.documentData.docNumber,
            accountFullName: this.documentData.account.fullName,
            accountCode: this.documentData.account.accountCode,
            contacts: this.documentData.account.contacts,
            accountGuid: this.documentData.account.accountGuid,
            accountUrl: this.documentData.accountUrl,
            identification: this.documentData.account.identification,
            branchOffice: this.documentData.account.branchOffice,
            balance: this.documentData.balance,
            dueAction: this.documentData.balance.dueAction,
            invoiceMoneyCode: this.documentData.balance.moneyCode,
            enabledPaymentOnline: this.documentData.enabledPaymentOnline,
            allowPartialPayments:
              this.documentData.allowPartialPayments == null ||
              this.documentData.allowPartialPayments == undefined
                ? true
                : this.documentData.allowPartialPayments,
            allowXMLDownLoad:
              this.documentData.allowXMLDownLoad == null ||
              this.documentData.allowXMLDownLoad == undefined
                ? false
                : this.documentData.allowXMLDownLoad,
            planWithoutAccountingSettings:
              this.documentData.planWithoutAccountingSettings,
            companyMoneyCode: this.documentData.company.moneyCode,
            userCode: String(this.documentData.metadata.created.userId),
            companyFullName: this.documentData.company.companyFullName,
            moneySettings: new MoneySettings(
              this.documentData.exchangeRate,
              this.documentData.minimumInvoiceBalance,
              this.documentData.company.moneyCode,
              this.documentData.balance.moneyCode,
              this.documentData.balance.dueAction,
              this.documentData.balance.totalValue
            ),
            isNonElectronicInvoice:
              this.documentData.isNonElectronicInvoice == null ||
              this.documentData.isNonElectronicInvoice == undefined
                ? false
                : this.documentData.isNonElectronicInvoice,
            paymentsData: this.createPaymentData(),
            aditionalInfo: documentData.aditionalInfo,
          };

          this.contact = this.getPrincipalContact(this.documentData);
          this.showSpinner = false;
          this.showErrorPage = false;
          this.isSaleNote = this.documentData.docClass == 'NV';

          // MODAL PAYMENT
        }
      })
      .catch(() => {
        this.showSpinner = false;
        this.showErrorPage = true;
      });
  }

  formatTotal() {
    return this.total.toLocaleString('de-DE');
  }

  /* START MODAL PAYMENT*/
  public calculateTotal(total: any) {
    let totalPay: number = 0;
    let totalInLocalMoneyToPay: number = 0;
    this.dictionaryInput = total;
    total.forEach((item: number) => {
      if (!isNaN(item) && item != null && item != undefined) {
        totalPay += item;
        totalInLocalMoneyToPay +=
          this.invoiceData.moneySettings.calculateTotalWithExchangeValue(item);
      }
    });
    this.totalInLocalMoney = totalInLocalMoneyToPay;
    this.total = totalPay;
  }

  handleChange(event, id, index) {
    this.onchangeInput(event, id, index);
    this.calculateTotal(this.dictionaryTotalInput);
  }

  setValueFirstInput(args) {
    const balanceFormat = args.balance
      .replace(/\s+/g, '')
      .replace(/,/g, '')
      .replace(/\$/g, '');
    let balance = this.invoiceData.moneySettings.getIsLocalMoney()
      ? parseInt(balanceFormat)
      : parseFloat(balanceFormat);
    this.dictionaryTotalInput.set(args.id, balance);
    this.calculateTotal(this.dictionaryTotalInput);
    return Number.isInteger(balance)
      ? balance.toString()
      : balance.toFixed(2).toString();
  }

  viewLabelDue() {
    let view = false;
    if(this.invoiceData != undefined){
      for (let index = 0; index < this.invoiceData.paymentsData.length; index++) {
        if (this.invoiceData.paymentsData[index].dueType == 1) {
          view = true;
        }
      }
    }
    return view;
  }

  public onchangeInput(event, id, index) {
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      if (id === item.id) {
        var balance = parseFloat(
          item.balance.replace(/\s+/g, '').replace(/,/g, '').replace(/\$/g, '')
        );
        var eventItem = parseFloat(
          event.detail === '' ? 0 : event.detail.replace(/,/g, '')
        );
        const { isPaymentValid, messageError } = this.validatePaymentAmount(
          eventItem,
          balance
        );
        this.paymentInputs.toArray()[index].setErrorInput(messageError || '');
        this.dictionaryValidInput.set(id, isPaymentValid);
        this.paymentInputs.toArray()[index].setValidator(() => isPaymentValid);
        if (!isNaN(eventItem) && eventItem > 0) {
          this.dictionaryTotalInput.set(id, eventItem);
        } else {
          this.dictionaryValidInput.delete(id);
          this.dictionaryTotalInput.delete(id);
        }
      }
      this.paymentInputs.toArray()[index].validate();
    }
  }

  validatePaymentAmount(paymentAmount: number, balance: number) {
    const isPaymentOutOfBalance = paymentAmount > balance;
    const isPaymentValid = !isPaymentOutOfBalance;
    let messageError: string | null = null;
    if (isPaymentOutOfBalance) {
      this._translate
        .get('ExceedsBalanceValue')
        .subscribe((message: string) => {
          messageError = message;
        });
    }

    return { isPaymentValid, messageError };
  }

  loadDocumentInfo() {
    if(this.invoiceData != undefined){
      this.dueName = `${this.invoiceData.docClass}-${this.invoiceData.docCode}`;
      this.dueConsecutive = `${this.invoiceData.docNumber}`;
      this.accountCode = `${this.invoiceData.accountCode}`;
    }
  }

  async getDues(): Promise<AccountBalanceModel[]> {
    let dataBody: AccountBalanceModel[] = [];
    try {
      dataBody = await this.documentViewManagerService.getDues(
        this.encryptedData,
        this.dueName,
        this.dueConsecutive,
        this.accountCode
      );
    } catch (error) {
      console.log(error);
    }
    dataBody.map((data) => (data.moneyCode = 'COP')); //! TO DELETE
    return dataBody;
  }

  showValidationNotification(): void {
    const minimumPaymentValue =
      this.invoiceData.moneySettings.getMinimumPaymentAmountLocal();
    const messageKey = this.invoiceData.moneySettings.getIsLocalMoney()
      ? 'MinimumPaymentValueLocal'
      : 'MinimumPaymentValue';
    this._translate
      .get(messageKey, { minimumPaymentValue })
      .subscribe((text: string) => {
        this.notificationMessage = text;
      });

    this.notificationStatus = true;
    const alertErrorElement = this.getAlertErrorElement();
    if (alertErrorElement && alertErrorElement.classList.contains('hide')) {
      this.siigoNotificationInlineValidation.toggleNotification();
    }
  }

  private getAlertErrorElement(): HTMLElement | null {
    if (!this.siigoNotificationInlineValidation) {
      return null;
    }
    const shadowRoot = this.siigoNotificationInlineValidation['el'].shadowRoot;
    return shadowRoot ? shadowRoot.querySelector('.alert') : null;
  }

  async redirectToPaymentGateway(providerPreference) {
    const result = await this.documentViewManagerService.createPreference(
      this.encryptedData,
      providerPreference
    );
    window.open(result.initPoint, '_self');
  }

  async toPay() {
    if (!this.loading)
      try {
        let hasErrors = false;
        this.paymentInputs.forEach((input) => {
          if (input.errorMessage !== undefined && input.errorMessage !== '') {
            hasErrors = true;
          }
        });

        if (hasErrors) {
          return;
        }
        if (
          this.totalInLocalMoney <
          this.invoiceData.moneySettings.getMinimumPaymentAmountLocal()
        ) {
          this.showValidationNotification();
          return;
        }
        if (Number(this.total) > 0) {
          const providerPreference =
            this._dataAdapterService.providerPreferenceDataAdapt(
              this.duesTypeInfo,
              this.dictionaryInput,
              this.contact,
              this.invoiceData,
              this.invoiceData.moneySettings
            );
          this.loading = true;
          await this.redirectToPaymentGateway(providerPreference);
        }
      } catch (error: any) {
        console.log(error);
        if (error.error.errors[0].detail != undefined) {
          if (
            error.error.errors[0].detail.includes('invalid token') ||
            error.error.errors[0].detail.includes('collector_id invalid')
          ) {
            /* this._translate.instant(
              'InactiveElectronicPayment'
            ); */
          } else {
            /* this._translate.instant(
              'TimeOutPaymentGateway'
            ); */
          }
        } else {
          /* this._translate.instant(
            'TimeOutPaymentGateway'
          ); */
        }
        this.loading = false;
      }
  }

  async openModalPayment() {
    this.localMoney = this.invoiceData.moneySettings.getIsLocalMoney();
    if (
      this.invoiceData.planWithoutAccountingSettings ||
      this.hasPaymentWithDue() == false
    ) {
      this.duesTypeInfo = this._dataAdapterService.buildDuesTypeFromInvoiceData(
        this.invoiceData
      );
    } else {
      //* otherwise, we get the dues associated with this invoice
      let dues: any = await this.getDues();
      this.duesTypeInfo = dues;
    }
    //* we adapt the data to the table
    this.data = this._dataAdapterService.duesTypeToDataTableAdapt(
      this.duesTypeInfo
    );

    const _balanceValue = 
      this.data[0].balance
        .replace(/\s+/g, '')
        .replace(/,/g, '')
        .replace(/\$/g, '');

    const defaultValueFormat = this.invoiceData.moneySettings.getIsLocalMoney() ? parseInt(_balanceValue): parseFloat(_balanceValue);

    this.defaultValue = this.invoiceData.moneySettings.getIsLocalMoney()
      ? defaultValueFormat
      : defaultValueFormat.toFixed(2);
    this.defaultValues = this.data.map((item) => {
      const defaultValuesFormat = parseFloat(
        item.balance.replace(/\s+/g, '').replace(/,/g, '').replace(/\$/g, '')
      );
      return this.invoiceData.moneySettings.getIsLocalMoney()
        ? defaultValuesFormat
        : defaultValuesFormat.toFixed(2);
    });
    if (this.invoiceData.allowPartialPayments) {
      this.setValueFirstInput(this.data[0]);
    } else {
      this.data.forEach((item) => {
        this.setValueFirstInput(item);
      });
    }
    this.isPreviewModalOpen = true;
  }

  getDefaultValues(index) {
    if (this.invoiceData.allowPartialPayments) {
      return index === 0 ? this.defaultValue : 0;
    }
    return this.defaultValues[index] || 0;
  }

  closeModalPayment() {
    this.isPreviewModalOpen = false;
  }

  getCuotaText(index: number): string {
    const suffixes = [
      'Primer',
      'Segunda',
      'Tercera',
      'Cuarta',
      'Quinta',
      'Sexta',
      'Séptima',
      'Octava',
      'Novena',
      'Décima',
      'Undécima',
      'Duodécima',
    ];
    return `${suffixes[index]} cuota`;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const modalElement =
        this.element.nativeElement.querySelector('#modalPayment');
      const shadowHost = modalElement ? modalElement.shadowRoot : null;
      if (shadowHost && this.invoiceData.paymentsData.length >= 3) {
        const style = document.createElement('style');
        style.textContent = `
          .panel-modal__body {
            width: auto !important;
            height: calc(100vh - 200px) !important;
            max-height: 350px !important;
            overflow-y: scroll !important;
          }
          .panel-modal__container {
            overflow: hidden !important;
          }
        `;
        shadowHost.appendChild(style);
      }
    }, 0);
  }

  /* FINISH MODAL PAYMENT*/

  private getPrincipalContact(data: DocumentData): any {
    return data.account.contacts.find((x) => x.isPrincipal === true);
  }

  private validateActivePayOnlineButtonDuePayment(): boolean {
    if (this.invoiceData.enabledPaymentOnline === false) {
      return false;
    }
    const invoiceBalanceValid =
      this.invoiceData.dueAction != undefined &&
      this.invoiceData.moneySettings.isDueIsGreaterThanBalance();
    const isPlanWithoutAccountingSettings =
      this.invoiceData.planWithoutAccountingSettings &&
      this.invoiceData.moneySettings.duesIsGreaterThanMinimumBalanceValidation(
        this.invoiceData.moneySettings.totalValue
      );

    return invoiceBalanceValid || isPlanWithoutAccountingSettings;
  }

  private validateActivePayOnlineButtonNotDuePayment(): boolean {
    if(this.invoiceData != undefined){
      if (this.invoiceData.paymentsData.length > 0) {
        let paymentTotal = this.invoiceData.moneySettings.totalValue;
        if (!this.invoiceData.moneySettings.getIsLocalMoney()) {
          const moneyForeing =
            this.invoiceData.moneySettings.totalValue *
            this.invoiceData.moneySettings.exchangeRate;
          paymentTotal = moneyForeing;
        }
        const showButton = paymentTotal >= 1000;
        return this.invoiceData.enabledPaymentOnline && showButton;
      }
  
      return this.invoiceData.enabledPaymentOnline;
    }
   return false;
  }

  hasPaymentWithDue(): boolean {
    if(this.invoiceData != undefined){
      return this.invoiceData.paymentsData.some(
        (payment: PaymentData) => payment.dueType == this.paymentTypeWithDue
      );
    }
    return false;
  }

  closeNotification() {
    this.typeNotification = '';
  }

  private getQueryParams() {
    this.encryptedData = this.documentViewManagerService.getEncryptedData(
      this._route,
      'data'
    );
    this.state = this.documentViewManagerService.getEncryptedData(
      this._route,
      'state'
    );
    this.extraFields = this.documentViewManagerService.getEncryptedData(
      this._route,
      'extraFields'
    );
  }

  public async downloadXml(): Promise<void> {
    const documentContent = this.documentData.document;
    this._downloadXml.downloadHtml(documentContent, 'document.pdf');
  }
  validateTypeNotification() {
    this._translate
      .get([
        'PaymentPendingConfirmation',
        'PaymentConfirmed',
        'PaymentDeclined',
      ])
      .subscribe((translations) => {
        switch (this.state.toLowerCase()) {
          case 'pending':
            this.typeNotification = 'info';
            this.messageNotification = translations.PaymentPendingConfirmation;
            break;
          case 'success':
            this.typeNotification = 'success';
            this.messageNotification = translations.PaymentPendingConfirmation;
            break;
          case 'failure':
            this.typeNotification = 'error';
            this.messageNotification = translations.PaymentDeclined;
            break;
          default:
            console.log(`No notification status`);
            break;
        }
      });
  }

  createPaymentData(): PaymentData[] {
    let paymentData: PaymentData[] = [];
    if (
      this.documentData.paymentTypes != null &&
      this.documentData.paymentTypes != undefined
    ) {
      this.documentData.paymentTypes.forEach((paymentType: PaymentTypes) => {
        paymentData.push({
          aCPaymentMeanId: paymentType.acPaymentMeanId,
          dueType: paymentType.dueType,
          inUse: paymentType.inUse,
        });
      });
    }
    return paymentData;
  }
}
